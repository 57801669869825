/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-25 15:17:36
 * @LastEditors: chenzhen
 */
import React from 'react';
import { useRoutes, useNavigate } from 'react-router-dom';
import { routePrefix, getRoutes } from './routeList';
import { getMenuList } from './menuList';
import { Suspense, lazy, useEffect, useState } from 'react';
import { Auth } from './auth';
import { MainLayout } from 'components/MainLayout/index';
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom';
import { Login } from 'pages/loginPage';
import { useDispatch } from 'react-redux';
import { setAuthorityList } from 'store/action/userInfos';
import ExternalAuthorization from 'pages/externalAuthorization';

const RouterConfig = ({ history }: any) => {
  const [menuList, setMenuList] = useState<any[]>([]);
  const [routerList, setRouterList] = useState<any[]>([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  // 设置菜单
  const setMenuListData = (data = []) => {
    setMenuList(getMenuList(data));
    dispatch(setAuthorityList(data));
    setRouterList(getRoutes(data));
  };

  useEffect(() => {
    window.sessionStorage.setItem(
      'environment',
      ['http://10.9.32.147'].includes(window.location.origin)
        ? '(测试)'
        : ['https://azinssu.glodon.com', 'https://uatazinssu.glodon.com'].includes(
            window.location.origin
          )
        ? ''
        : ''
    );
  }, []);

  useEffect(() => {
    // setMenuListData([]);
    if (!menuList.length || !routerList.length) return;
    // 判断当前地址是否存在于路由中
    const checkRoutes = routerList.filter(item => location.pathname === '/' + item.path);
    // 错误路由进行重定向;
    if (!checkRoutes.length) {
      const childPath =
        menuList[0].children && menuList[0].children.length
          ? `/${menuList[0].key}/${menuList[0].children[0].key}`
          : `/${menuList[0].key}`;
      navigate(childPath);
    }
  }, [menuList, routerList]);

  return (
    <>
      {location.pathname === '/login' ? (
        <Routes>
          <Route path="/login" element={<Login />} />
        </Routes>
      ) : location.pathname === '/authorization' ? (
        <Routes>
          <Route path="/authorization" element={<ExternalAuthorization />} />
        </Routes>
      ) : (
        <>
          <Auth setMenuListData={setMenuListData} />
          <MainLayout breadcrumb={false} menuList={menuList} routePrefix={routePrefix} className="">
            {useRoutes(routerList)}
          </MainLayout>
        </>
      )}
    </>
  );
};

export default RouterConfig;
