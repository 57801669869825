/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-07 13:46:43
 * @LastEditors: chenzhen
 */
import { axiosSubmit } from 'api/axios';

/**
 * ## 账号密码登录
 * @param {*} params
 * @returns
 */
export const loginAccount = params => axiosSubmit.post(`/login`, params);

/**
 * ## 验证码登录
 * @param {*} params
 * @returns
 */
export const loginVerificationCode = params => axiosSubmit.post(`/login_by_code`, params);

/**
 * ## 获取验证码
 * @param {*} params
 * @returns
 */
export const getVerifyCode = params => axiosSubmit.get(`/login_verify_message`, { params });

/**
 * ## 退出登录
 * @param {*}
 * @returns
 */
export const Logout = () => axiosSubmit.post(`/logout`);
