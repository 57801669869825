/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-14 23:17:16
 * @LastEditors: chenzhen
 */
import { axiosSubmit } from 'api/axios';

/**
 * ## 校验邮箱唯一
 * @param {*} params
 * @returns
 */
export const checkEmail = (email = '') => axiosSubmit.post(`/common/check/email?email=${email}`);

/**
 * ## 校验手机号唯一
 * @param {*} params
 * @returns
 */
export const checkPhone = (phone = '') =>
  axiosSubmit.post(`/common/check/phone?phone_number=${phone}`);

/**
 * ## 校验手机号唯一
 * @param {*} params
 * @returns
 */
export const checkUserName = (user_name = '') =>
  axiosSubmit.post(`/common/check/username?user_name=${user_name}`);

/**
 * ## 校验统一社会信用代码唯一
 * @param {*} params
 * @returns
 */
export const checkUnifiedSocialCreditCode = params =>
  axiosSubmit.get(`/biz/filing/unified_social_credit_code/check`, { params });

/**
 * ## 保险机构列表
 * @param {*}
 * @returns
 */
export const getInsuranceList = () => axiosSubmit.get(`/common/insurance`);

/**
 * ## 地区列表
 * @param {*}
 * @returns
 */
export const getRegionTreeList = () => axiosSubmit.get(`/common/region/tree`);

/**
 * ## 根据字段类型获取字典列表数据
 * @param {*}
 * @returns
 */
export const getDictList = type => axiosSubmit.get(`/dict/${type}`);
