/*
 * @Description:
 * @Author: chenzhen
 * @Date: 2024-07-17 10:17:28
 * @LastEditors: chenzhen
 */
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router';
import { queryUserInfo } from 'api/userInfo';
import { setCookie } from 'api/axios';
import { parseUrl } from 'utils/index';

const ExternalAuthorization = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const token = searchParams.get('authorization_token');
    const errorMessage = searchParams.get('error_message');

    if (token) {
      setCookie('insuranceToken', token);
      queryUserInfo()
        .then(res => {
          const { redirectUrl }: { redirectUrl?: string } = parseUrl(location.search);
          location.replace(redirectUrl ? window.decodeURIComponent(redirectUrl) : '/');
        })
        .catch(err => {
          navigate('/login?error_message=' + errorMessage);
        });
    }

    if (errorMessage) {
      navigate('/login?error_message=' + errorMessage);
    }
  }, [dispatch]);
  return <div></div>;
};

export default ExternalAuthorization;
