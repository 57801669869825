/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-08 10:07:51
 * @LastEditors: chenzhen
 */
import { axiosSubmit } from 'api/axios';

/**
 * ## 获取个人信息
 * @param {*}
 * @returns
 */
export const queryUserInfo = () => axiosSubmit.get(`/self`);

/**
 * ## 修改手机号
 * @param {*}
 * @returns
 */
export const changePhone = params => axiosSubmit.put(`/self/phone_number/modify`, params);

/**
 * ## 发送验证码
 * @param {*}
 * @returns
 */
export const changePhoneVerifyCode = params => axiosSubmit.get(`/self/send/message`, { params });

/**
 * ## 修改个人信息
 * @param {*}
 * @returns
 */
export const changeUserInfo = params => axiosSubmit.put(`/self`, params);

/**
 * ## 修改密码
 * @param {*}
 * @returns
 */
export const changePassword = params => axiosSubmit.put(`/self/password/modify`, params);

/**
 * ## 菜单列表(包含父节点)
 * @param {*}
 * @returns
 */
export const queryUserMenu = (role_id: string) =>
  axiosSubmit.get(`/menu/list/${role_id}/include_parent`);
