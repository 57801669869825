/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-25 13:42:19
 * @LastEditors: chenzhen
 */
import React, { useMemo, useState, useCallback } from 'react';
import { Tabs, Input, Form, Button, message } from 'antd';
import type { TabsProps } from 'antd';
import moment from 'moment';
import './index.scss';
import { SimpleVerify } from './simpleVerify';
import { FieldType } from './type';
import { loginAccount, getVerifyCode, loginVerificationCode } from 'api/loginApi';
import { setCookie } from 'api/axios';
import { parseUrl } from 'utils/index';
import useInterval from 'hooks/useSetInterval';
import { IMAGE_LIST } from 'utils/enumList';

export const Login: React.FC = () => {
  const [loadingStatus, setLoadingStatus] = useState(false);
  const [count, setCount] = useState(0);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [tabVal, setTabVal] = useState('1');
  const [form] = Form.useForm();
  const setBtnStatusFn = useCallback(status => {
    setBtnDisabled(status);
  }, []);
  const environment = useMemo(() => window.sessionStorage.getItem('environment'), []);

  useInterval(
    () => {
      const nextCount = count - 1;
      setCount(nextCount);
    },
    count ? 1000 : null
  );

  const handleSendMessageFn = useCallback(async () => {
    const verifyCodeTime = localStorage.getItem('VERIFYCODETIME');
    const s = verifyCodeTime ? moment(verifyCodeTime).diff(moment(), 's') : 0;
    if (s > 0) {
      setCount(s);
      return false;
    }
    localStorage.removeItem('VERIFYCODETIME');
    localStorage.setItem('VERIFYCODETIME', moment().add('1', 'm').format('YYYY-MM-DD HH:mm:ss'));
    try {
      await form.validateFields(['phone']);
      const values = form?.getFieldsValue();
      setCount(60);
      getVerifyCode({ phone_number: values?.phone })
        .then(res => {
          if (res.data.code === 2000) {
            message.success('验证码发送成功，请注意查收');
          } else {
            localStorage.removeItem('VERIFYCODETIME');
            setCount(0);
            message.error(res.data.message || '验证码发送失败，请稍后重试');
          }
        })
        .catch(err => {
          localStorage.removeItem('VERIFYCODETIME');
          message.error(err.data.message || '验证码发送失败，请稍后重试');
          setCount(0);
        });
    } catch (e) {
      localStorage.removeItem('VERIFYCODETIME');
      setCount(0);
    }
  }, [form]);

  // 切换tab
  const onChangeTabs = index => {
    setTabVal(index);
    setBtnStatusFn(true);
  };

  // 登录
  const onLogin = useCallback(
    values => {
      setLoadingStatus(true);
      const { phone, verificationCode, password, username } = values;
      if (tabVal === '1') {
        const verificationCodeParams = {
          phone_no: phone,
          verify_code: verificationCode,
        };
        loginVerificationCode(verificationCodeParams)
          .then(res => {
            if (res.data.code === 2000) {
              // 存token
              setCookie('insuranceToken', res.data.data);
              setLoadingStatus(false);
              // 跳转
              const { redirectUrl }: { redirectUrl?: string } = parseUrl(location.search);
              location.replace(redirectUrl ? window.decodeURIComponent(redirectUrl) : '/');
            } else {
              setLoadingStatus(false);
              message.error(res.data.message || '登录失败，请重新登录');
            }
          })
          .catch(err => {
            setLoadingStatus(false);
            message.error(err.data.message || '登录失败，请重新登录');
          });
      } else {
        const accountParams = { password: password, username: username };
        loginAccount(accountParams)
          .then(res => {
            if (res.data.code === 2000) {
              // 存token
              setCookie('insuranceToken', res.data.data);
              setLoadingStatus(false);
              // 跳转
              const { redirectUrl }: { redirectUrl?: string } = parseUrl(location.search);
              location.replace(redirectUrl ? window.decodeURIComponent(redirectUrl) : '/');
            } else {
              setLoadingStatus(false);
              message.error(res.data.message || '登录失败，请重新登录');
            }
          })
          .catch(err => {
            setLoadingStatus(false);
            message.error(err.data.message || '登录失败，请重新登录');
          });
      }
    },
    [tabVal]
  );

  // 验证码登录tab
  const verificationCodeLogin = (
    <div>
      <Form size={'large'} onFinish={onLogin} form={form}>
        <Form.Item<FieldType>
          name="phone"
          rules={[
            { required: true, message: '请输入手机号!' },
            {
              pattern: /^1[3-9]\d{9}$/,
              message: '请输入正确的手机号格式！',
            },
          ]}
        >
          <Input className="global-input" placeholder="请输入手机号" />
        </Form.Item>
        <Form.Item<FieldType>
          name="verificationCode"
          rules={[{ required: true, message: '请输入验证码!' }]}
        >
          <Input
            className="global-input"
            placeholder="请输入验证码"
            suffix={
              <span onClick={!count ? handleSendMessageFn : undefined}>
                {!count ? '发送验证码' : count + '秒后重发'}
              </span>
            }
          />
        </Form.Item>
        <Form.Item>
          <SimpleVerify
            width={400}
            height={40}
            movedColor="rgba(56,225,255,0.8)"
            bgColor="rgba(56,225,255,0.2)"
            borderRadius={2}
            borderColor="none"
            success={() => {
              setBtnStatusFn(false);
            }}
          />
        </Form.Item>
        <Form.Item className="login-btn">
          <Button type="primary" htmlType="submit" disabled={btnDisabled}>
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  // 账号密码登录tab
  const accountLogin = (
    <div>
      <Form size={'large'} onFinish={onLogin} form={form}>
        <Form.Item<FieldType>
          name="username"
          rules={[{ required: true, message: '请输入用户名!' }]}
        >
          <Input className="global-input" placeholder="请输入用户名" />
        </Form.Item>
        <Form.Item<FieldType>
          name="password"
          rules={[
            { required: true, message: '请输入密码!' },
            {
              pattern: /^.{6,20}/,
              message: '密码长度必须为6~20个字符',
            },
          ]}
        >
          <Input.Password className="global-input" placeholder="请输入密码" />
        </Form.Item>
        <Form.Item>
          <SimpleVerify
            width={400}
            height={40}
            movedColor="rgba(56,225,255,0.8)"
            bgColor="rgba(56,225,255,0.2)"
            borderRadius={2}
            borderColor="none"
            success={() => {
              setBtnStatusFn(false);
            }}
          />
        </Form.Item>
        <Form.Item className="login-btn">
          <Button type="primary" htmlType="submit" disabled={btnDisabled} loading={loadingStatus}>
            登录
          </Button>
        </Form.Item>
      </Form>
    </div>
  );

  const loginItems: TabsProps['items'] = [
    {
      key: '1',
      label: '验证码登录',
      children: verificationCodeLogin,
    },
    {
      key: '2',
      label: '账号密码登录',
      children: accountLogin,
    },
  ];
  return (
    <div
      className="login"
      style={{
        background: `url(${IMAGE_LIST.loginbg})`,
        backgroundSize: 'cover',
      }}
    >
      <div className="login-header">
        <h1>
          陕西省安责险综合信息管理平台{environment && <div className="env">{environment}</div>}
        </h1>
      </div>
      <div className="login-content">
        <Tabs
          defaultActiveKey="1"
          items={loginItems}
          onChange={onChangeTabs}
          destroyInactiveTabPane={true}
        />
      </div>
      <div className="login-footer">
        <div className="filing-text">
          <div>周一至周五&nbsp;&nbsp;&nbsp;&nbsp;9:00-18:00</div>
          <div>
            运营保障中心客服电话：
            <span className="filing-text-phone">400-0022-066</span>
          </div>
        </div>
        <img src={IMAGE_LIST.azxQRcode} alt="" />
      </div>
    </div>
  );
};
