/**
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-27 20:06:08
 * @LastEditors: chenz-l
 */
const SET_USER_AUTH_BTN = 'SET_USER_AUTH_BTN';
const SET_USER_INFO = 'SET_USER_INFO';
const SET_ACTIVE_FLAG = 'SET_ACTIVE_FLAG';
const SET_WANGMING = 'SET_WANGMING';
const SET_USER_PHONE = 'SET_USER_PHONE';
const SET_USER_EMAIL = 'SET_USER_EMAIL';
const SET_AUTHORITY_LIST = 'SET_AUTHORITY_LIST';
export {
  SET_USER_AUTH_BTN,
  SET_USER_INFO,
  SET_ACTIVE_FLAG,
  SET_WANGMING,
  SET_USER_PHONE,
  SET_USER_EMAIL,
  SET_AUTHORITY_LIST,
};
