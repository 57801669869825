/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-27 20:06:23
 * @LastEditors: chenzhen
 */
import {
  SET_USER_AUTH_BTN,
  SET_USER_INFO,
  SET_WANGMING,
  // SET_ACTIVE_FLAG
  SET_USER_PHONE,
  SET_USER_EMAIL,
  SET_AUTHORITY_LIST,
} from '../constants/userInfos';

interface SetUserAuthBtnAction {
  type: string;
  auth?;
  wangming?: string;
  infos?: string;
  phone?: string;
  email?: string;
  authorityList?: string[];
}

export const setUserInfo = (infos: string): SetUserAuthBtnAction => {
  return {
    type: SET_USER_INFO,
    infos,
  };
};

export const setUserPhone = (phone: string): SetUserAuthBtnAction => {
  return {
    type: SET_USER_PHONE,
    phone,
  };
};

export const setUserEmail = (email: string): SetUserAuthBtnAction => {
  return {
    type: SET_USER_EMAIL,
    email,
  };
};
export const setAuthorityList = (authorityList: string[]): SetUserAuthBtnAction => {
  return {
    type: SET_AUTHORITY_LIST,
    authorityList,
  };
};
