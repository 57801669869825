/*
 * @Description:
 * @Author: chenzhen
 * @Date: 2024-05-21 11:02:43
 * @LastEditors: chenzhen
 */
import * as type from '../constants/dataFilter';

interface InitStateTypes {
  filter_time: string;
  statistical_analysis;
  region_list;
  insurance_list;
  globalInsurance: { code: string; name: string };
  globalRegion: {
    province;
    city;
    region;
  };
}

const initState = {
  filter_time: '',
  statistical_analysis: [],
  region_list: [],
  insurance_list: [],
  globalInsurance: { code: '全部保险机构', name: '全部保险机构' },
  globalRegion: {
    province: { code: '610000', name: '陕西省' },
    city: { code: '全部', name: '全部' },
    region: { code: '全部', name: '全部' },
  },
};

const redurce = (state: InitStateTypes = initState, action) => {
  switch (action.type) {
    case type.SET_FILTER_TIME:
      state.filter_time = action.filter_time;
      return Object.assign({}, state);
    case type.SET_STATISCAL_ANALYSIS:
      state.statistical_analysis = action.statistical_analysis;
      return Object.assign({}, state);
    case type.SET_REGION_LIST:
      state.region_list = action.region_list;
      return Object.assign({}, state);
    case type.SET_INSURANCE_LIST:
      state.insurance_list = action.insurance_list;
      return Object.assign({}, state);
    case type.SET_GLOBAL_INSURANCE:
      state.globalInsurance = action.globalInsurance;
      return Object.assign({}, state);
    case type.SET_GLOBAL_REGION:
      state.globalRegion = action.globalRegion;
      return Object.assign({}, state);
    default:
      return state;
  }
};
export default redurce;
