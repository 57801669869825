/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-16 16:48:25
 * @LastEditors: chenzhen
 */
const SET_FILTER_TIME = 'SET_FILTER_TIME';
const SET_STATISCAL_ANALYSIS = 'SET_STATISCAL_ANALYSIS';
const SET_REGION_LIST = 'SET_REGION_LIST';
const SET_INSURANCE_LIST = 'SET_INSURANCE_LIST';
const SET_GLOBAL_INSURANCE = 'SET_GLOBAL_INSURANCE';
const SET_GLOBAL_REGION = 'SET_GLOBAL_REGION';
export {
  SET_FILTER_TIME,
  SET_STATISCAL_ANALYSIS,
  SET_REGION_LIST,
  SET_INSURANCE_LIST,
  SET_GLOBAL_INSURANCE,SET_GLOBAL_REGION
};
