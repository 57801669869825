/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-25 15:45:06
 * @LastEditors: chenzhen
 */
import { useCallback, useEffect, useState } from 'react';
import { setUserInfo } from 'store/action/userInfos';
import { message } from 'antd';
import { queryUserInfo, queryUserMenu } from 'api/userInfo';
import { useSelector, useDispatch } from 'react-redux';
import { setGlobalInsurance, setGlobalRegion } from 'store/action/dataFilter';

export const Auth = (props: any) => {
  const { setMenuListData, setRouterListData } = props;
  const dispatch = useDispatch();
  const [roleId, setRoleId] = useState<string>('');

  // 获取用户信息
  const queryUser = () =>
    new Promise((resolve, reject) => {
      queryUserInfo()
        .then(res => {
          const { code, data } = res.data;
          if (code === 2000) {
            dispatch(setUserInfo(data || {}));
            data?.insurance_code &&
              dispatch(
                setGlobalInsurance({ code: data?.insurance_code, name: data?.insurance_name })
              );
            setRoleId(data.role_id);
            resolve(true);
          } else {
            message.error(res.data.message, 4);
            resolve(false);
          }
        })
        .catch(err => {
          resolve(false);
          message.error('暂无获取到用户信息');
        });
    });
  // 获取权限信息
  const queryUserMenuInfo = () => {
    const defData = sessionStorage.getItem('userMenuList');
    if (Array.isArray(defData)) {
      setMenuListData(defData);
      return;
    }
    roleId !== '' &&
      queryUserMenu(roleId)
        .then(res => {
          const { code, data } = res.data;
          if (code === 2000) {
            sessionStorage.setItem('userMenuList', data.length ? data : null);
            setMenuListData(data);
          }
        })
        .catch(err => {
          console.error(err);
        });
  };
  const queryData = async () => {
    const isUser = await queryUser();
    if (!isUser) return;
    queryUserMenuInfo();
  };

  useEffect(() => {
    queryData();
  }, [roleId]);
  return null;
};
