/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-18 21:46:30
 * @LastEditors: chenzhen
 */
import React, { useEffect } from 'react';
import ReactDOM from 'react-dom/client';
import { Route, Routes, BrowserRouter, Navigate } from 'react-router-dom';
import store from './store';
import { Provider } from 'react-redux';
import 'static/index.scss';
import 'static/antd.scss';
import RouterConfig from 'router/routerConfig';
import { ConfigProvider } from 'antd';
import zhCN from 'antd/es/locale/zh_CN';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
  <ConfigProvider locale={zhCN}>
    <BrowserRouter>
      <Provider store={store()}>
        <RouterConfig />
      </Provider>
    </BrowserRouter>
  </ConfigProvider>
);
