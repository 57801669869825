/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-07 13:36:06
 * @LastEditors: chenzhen
 */
import axios from 'axios';
import cookie from 'js-cookie';
// import { getCookieOption } from '@util/index.js';
// axios请求
axios.defaults.headers.post['Content-Type'] = 'application/json';
axios.defaults.headers.put['Content-Type'] = 'application/json';
// IE取消缓存
axios.defaults.headers.get['Cache-Control'] = 'no-cache';
axios.defaults.headers.get['Pragma'] = 'no-cache';
axios.defaults.timeout = 60000;
axios.defaults.baseURL = process.env.REACT_APP_BASE_URL || '';

axios.interceptors.request.use(
  function (config) {
    const token = cookie.get('insuranceToken');
    // const configUrl = config.url.split('?')[0];
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  function (error) {
    console.error('请求出错');
    return Promise.reject(error);
  }
);

// 添加响应拦截器
axios.interceptors.response.use(
  function (response) {
    userExceptionHandling(response);
    return response;
  },
  function (error) {
    userExceptionHandling(error.response);
    return Promise.reject(error.response);
  }
);

export const axiosSubmit = axios;

const userExceptionHandling = response => {
  /**
   * code=3003 Invalid token
   * code=3005 Token will expire
   * code=4001 Unauthorized
   * code=4004 Not Found Authentication Token
   */
  if ([3005, 3003, 4001, 4004, 4107].includes(response.data.code)) {
    removeCookie('insuranceToken');
    // response.data.code === 3005 && (location.href = `${response.data.data}`);
    location.href = `/login?redirectUrl=${window.encodeURIComponent(location.href)}`;
  }
};

/**
 * ## 删除cookie
 * @param {string} name cookie 名称
 */
export const removeCookie = name => {
  // let option = getCookieOption();
  // 清除菜单、路由和按钮缓存
  sessionStorage.setItem('userMenuList', '');
  sessionStorage.setItem('userRouterList', '');
  cookie.remove(name);
};

/**
 * ## 保存cookie
 * @param {string} name cookie 名称
 */
export const setCookie = (name, value) => {
  // let option = getCookieOption();
  cookie.set(name, value);
};
