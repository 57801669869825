/**
 * Hooks 中使用 setInterval 封装 userInterval
 *
 * callback setInterval里面要执行的函数
 * delay 多少秒执行一次
 * return ''
 */
import { useEffect, useRef } from 'react';

function useInterval(callback, delay) {
  const savedCallback = useRef<any>();

  useEffect(() => {
    savedCallback.current = callback;
  });

  useEffect(() => {
    function tick() {
      savedCallback?.current?.();
    }

    if (delay !== null) {
      const id = setInterval(tick, delay);

      return () => clearInterval(id);
    }
  }, [delay]);
}
export default useInterval;
