/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-16 16:47:33
 * @LastEditors: chenzhen
 */
import {
  SET_FILTER_TIME,
  SET_STATISCAL_ANALYSIS,
  SET_REGION_LIST,
  SET_INSURANCE_LIST,
  SET_GLOBAL_INSURANCE,
  SET_GLOBAL_REGION,
} from '../constants/dataFilter';

interface SetUserAuthBtnAction {
  type: string;
  filter_time?: string[];
  statistical_analysis?;
  region_list?;
  insurance_list?;
  globalInsurance?: {
    code: string;
    name: string;
  };
  globalRegion?: {
    province;
    city;
    region;
  };
}

export const setFilterTime = (filter_time: string[]): SetUserAuthBtnAction => {
  return {
    type: SET_FILTER_TIME,
    filter_time,
  };
};

export const setStatisticalAnalysis = (statistical_analysis): SetUserAuthBtnAction => {
  return {
    type: SET_STATISCAL_ANALYSIS,
    statistical_analysis,
  };
};

export const setRegionList = (region_list): SetUserAuthBtnAction => {
  return {
    type: SET_REGION_LIST,
    region_list,
  };
};

export const setInsuranceList = (insurance_list): SetUserAuthBtnAction => {
  return {
    type: SET_INSURANCE_LIST,
    insurance_list,
  };
};

export const setGlobalInsurance = (globalInsurance: { code; name }): SetUserAuthBtnAction => {
  return {
    type: SET_GLOBAL_INSURANCE,
    globalInsurance,
  };
};

export const setGlobalRegion = (globalRegion: { province; city; region }): SetUserAuthBtnAction => {
  return {
    type: SET_GLOBAL_REGION,
    globalRegion,
  };
};
