/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-27 19:46:06
 * @LastEditors: chenz-l
 */
import { combineReducers } from 'redux';
import userInfos from './userInfos';
import dataFilter from './dataFilter';

const reducers = combineReducers({
  userInfos,
  dataFilter,
});

export default reducers;
