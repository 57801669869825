/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-16 14:28:20
 * @LastEditors: chenzhen
 */

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dropdown, Row, Col, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { getDictList, getRegionTreeList } from 'api/common';
import { InsuranceItemType, RegionTreeType } from './types';
import { setGlobalRegion } from 'store/action/dataFilter';
import { useDispatch, useSelector } from 'react-redux';
import { StateTypes, UserInfosTypes, DataFilterTypes } from 'store/types';
import { IMAGE_LIST } from 'utils/enumList';

const RegionFilter = ({ menuKey }: { menuKey: string | undefined }) => {
  const dispatch = useDispatch();
  const [provinceList, setProvinceList] = useState<InsuranceItemType[]>();
  const [cityList, setCityList] = useState<InsuranceItemType[]>();
  const [regionList, setRegionList] = useState<InsuranceItemType[]>();
  const [isOpenRegion, setIsOpenRegion] = useState<boolean>(false);
  const [activeProvince, setActiveProvince] = useState<string | undefined>('陕西省');
  const [activeCity, setActiveCity] = useState<string | null>(null);
  const [activeRegion, setActiveRegion] = useState<string | null>(null);
  const [regionAll, setRegionAll] = useState<RegionTreeType>();
  const userInfos: UserInfosTypes = useSelector((state: StateTypes) => state.userInfos);
  const dataFilter: DataFilterTypes = useSelector((state: StateTypes) => state.dataFilter);
  const [isOpenRegionList, setIsOpenRegionList] = useState<boolean>(false);
  const [userRegionalHierarchy, setUserRegionalHierarchy] = useState<string>();
  const [regionDisabled, setRegionDisabled] = useState<boolean>(false);
  const [initializationDisabled, setInitializationDisabled] = useState<boolean>(false);
  const [isOpenProvinceList, setIsOpenProvinceList] = useState<boolean>(true);
  const [isOpenCityList, setIsOpenCityList] = useState<boolean>(true);
  const [isOpenDistrictList, setIsOpenDistrictList] = useState<boolean>(true);
  const dropdownRef = useRef<HTMLDivElement>(null);
  const disabledMenuList = [
    'statisticalAnalysis',
    'runReport',
    'excellentEnterprise',
    'administrator',
    'changeUserInfo',
  ];

  useEffect(() => {
    if (initializationDisabled) {
      setRegionDisabled(true);
      return;
    }
    switch (menuKey) {
      case 'homePage':
        setRegionDisabled(false);
        break;
      case 'regionSituation':
        setRegionDisabled(false);
        break;
      case 'insuranceOverview':
        setRegionDisabled(false);
        break;
      case 'statisticalAnalysis':
        setRegionDisabled(true);
        break;
      case 'runReport':
        setRegionDisabled(true);
        break;
      case 'excellentEnterprise':
        setRegionDisabled(true);
        break;
      case 'dataUpload':
        setRegionDisabled(false);
        break;
      case 'administrator':
        setRegionDisabled(true);
        break;
      case 'changeUserInfo':
        setRegionDisabled(true);
        break;
      case 'filings':
        setRegionDisabled(true);
        break;
      default:
        setRegionDisabled(false);
    }
  }, [menuKey, initializationDisabled]);

  useEffect(() => {
    let globalRegion;
    if (menuKey && disabledMenuList?.includes(menuKey)) {
      globalRegion = {
        province: { code: '610000', name: '陕西省' },
        city: { code: '全部', name: '全部' },
        region: { code: '全部', name: '全部' },
      };
    } else {
      globalRegion = dataFilter?.globalRegion;
    }
    // 根据userInfo中的省市区设置默认筛选条件
    getRegionTreeList()
      .then(res => {
        const { code, data } = res.data;
        if (code == 2000) {
          setRegionAll(data);
          let regionDataList: RegionTreeType[] = [];
          [data].forEach(item => {
            item?.children?.forEach(i => {
              regionDataList = [...regionDataList, ...i.children];
            });
          });
          // 如果默认不是陕西省
          if (userInfos?.region_code && userInfos?.region_code !== '610000') {
            const provinceCode = [data].map(item => item.code);
            if (provinceCode.includes(userInfos?.region_code)) {
              setUserRegionalHierarchy('province');
              const province = [data].filter(i => i.code === userInfos?.region_code);
              setProvinceList(province);
              if (menuKey && disabledMenuList?.includes(menuKey)) {
                setActiveCity('全部');
                setActiveRegion('全部');
                setIsOpenRegionList(false);
              }
            }
            const cityCode = data?.children.map(item => item.code);
            if (cityCode.includes(userInfos?.region_code)) {
              setUserRegionalHierarchy('city');
              const city = data?.children.filter(i => i.code == userInfos?.region_code);
              setCityList([city?.[0]]);
              setRegionList(city?.[0]?.children);
              globalRegion = {
                ...globalRegion,
                city: { code: userInfos?.region_code, name: userInfos?.region_name },
              };
              setActiveCity(userInfos?.region_name);
              if (menuKey && disabledMenuList?.includes(menuKey)) {
                setActiveRegion('全部');
                setIsOpenRegionList(false);
              }
              setIsOpenProvinceList(false);
              setIsOpenCityList(true);
              setIsOpenDistrictList(true);
            }
            const regionCode = regionDataList.map(item => item.code);
            if (regionCode.includes(userInfos?.region_code)) {
              setUserRegionalHierarchy('region');
              const regionNew = regionDataList.filter(i => i?.code === userInfos?.region_code);
              setRegionList(regionNew);
              globalRegion = {
                ...globalRegion,
                region: { code: userInfos?.region_code, name: userInfos?.region_name },
              };
              setActiveRegion(userInfos?.region_name);
              if (menuKey && disabledMenuList?.includes(menuKey)) {
                setIsOpenRegionList(false);
              }
              setInitializationDisabled(true);
              setIsOpenProvinceList(false);
              setIsOpenCityList(false);
              setIsOpenDistrictList(true);
            }
          } else {
            // 如果默认是陕西省
            const province = [data].filter(i => i.code === userInfos?.region_code);
            setProvinceList(province);
            setCityList(data?.children);
            globalRegion = {
              ...globalRegion,
              province: { code: '610000', name: '陕西省' },
            };
            setActiveProvince(userInfos?.region_name || '陕西省');
            setActiveProvince(userInfos?.region_name || '陕西省');
            setIsOpenDistrictList(false);
            if (menuKey && disabledMenuList?.includes(menuKey)) {
              setActiveCity('全部');
              setActiveRegion('全部');
              setIsOpenRegionList(false);
            }
          }
          dispatch(setGlobalRegion(globalRegion));
        }
      })
      .catch(err => {
        console.log(err);
      });
  }, [menuKey, dispatch]);

  // 点击Dropdown外时关闭弹框
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenRegion(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenRegion]);

  // 切换省
  const changeProvince = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const target = event.target as HTMLElement;
      const province = provinceList?.filter(
        (item: InsuranceItemType) => target.innerText === item.name
      );
      setActiveProvince(province?.[0]?.name);
      setActiveCity('全部');
      dispatch(setGlobalRegion({ ...dataFilter.globalRegion, province: province?.[0] }));
      setIsOpenRegionList(false);
      dispatch(
        setGlobalRegion({
          ...dataFilter.globalRegion,
          city: { code: '全部', name: '全部' },
          region: { code: '全部', name: '全部' },
        })
      );
    },
    [provinceList, dataFilter]
  );
  // 切换市
  const changeCity = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const target = event.target as HTMLElement;
      const city = cityList?.filter((item: InsuranceItemType) => target.innerText === item.name);
      city?.[0]
        ? dispatch(setGlobalRegion({ ...dataFilter.globalRegion, city: city?.[0] }))
        : dispatch(
            setGlobalRegion({ ...dataFilter.globalRegion, city: { code: '全部', name: '全部' } })
          );
      const region = regionAll?.children.filter(item => item?.code === city?.[0]?.code);
      setRegionList(region?.[0]?.children);
      setActiveCity(dataFilter?.globalRegion?.city?.name);
      setActiveRegion('全部');
      target.innerText === '全部' ? setIsOpenRegionList(false) : setIsOpenRegionList(true);
      target.innerText === '全部' && setIsOpenRegion(false);
      dispatch(
        setGlobalRegion({
          ...dataFilter.globalRegion,
          region: { code: '全部', name: '全部' },
        })
      );
    },
    [cityList, dataFilter]
  );
  // 切换区
  const changeRegion = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const target = event.target as HTMLElement;
      const region = regionList?.filter(
        (item: InsuranceItemType) => target.innerText === item.name
      );
      region?.[0]
        ? dispatch(setGlobalRegion({ ...dataFilter.globalRegion, region: region?.[0] }))
        : dispatch(
            setGlobalRegion({
              ...dataFilter.globalRegion,
              region: { code: '全部', name: '全部' },
            })
          );
      setActiveRegion(dataFilter?.globalRegion?.region?.name);
      setIsOpenRegion(false);
    },
    [regionList, dataFilter]
  );

  const dropdownRender = menu => (
    <div
      ref={dropdownRef}
      className="region-dropdown-content"
      style={
        isOpenRegionList && userRegionalHierarchy !== 'city'
          ? { width: '600px' }
          : { width: '400px' }
      }
    >
      <Row>
        {isOpenProvinceList && (
          <Col span={isOpenRegionList ? 8 : 12} className="region-col">
            <div className="region-title">省</div>
            {provinceList?.map((item, key: number) => {
              return (
                <div key={key} className="region-box">
                  <button
                    className={`region-btn ${activeProvince === item.name ? 'region-active' : ''}`}
                    onClick={changeProvince}
                  >
                    {item.name}
                  </button>
                </div>
              );
            })}
          </Col>
        )}
        {isOpenCityList && (
          <Col
            span={
              isOpenRegionList && userRegionalHierarchy === 'city'
                ? 12
                : isOpenRegionList && userRegionalHierarchy !== 'city'
                ? 8
                : 12
            }
            className="region-col"
          >
            <div className="region-title">市</div>
            {userRegionalHierarchy !== 'city' && (
              <div className="region-box">
                <button
                  className={`region-btn ${activeCity === '全部' ? 'region-active' : ''}`}
                  onClick={changeCity}
                >
                  全部
                </button>
              </div>
            )}
            {cityList?.map((item, key: number) => {
              return (
                <div key={key} className="region-box">
                  <button
                    className={`region-btn ${activeCity === item.name ? 'region-active' : ''}`}
                    onClick={changeCity}
                  >
                    {item.name}
                  </button>
                </div>
              );
            })}
          </Col>
        )}
        {(isOpenRegionList || isOpenDistrictList) && (
          <Col
            span={userRegionalHierarchy === 'city' || userRegionalHierarchy === 'region' ? 12 : 8}
            className="region-col"
          >
            <div className="region-title">区</div>
            {userRegionalHierarchy !== 'region' && (
              <div className="region-box">
                <button
                  className={`region-btn ${activeRegion === '全部' ? 'region-active' : ''}`}
                  onClick={changeRegion}
                >
                  全部
                </button>
              </div>
            )}
            {regionList?.map((item, key: number) => {
              return (
                <div key={key} className="region-box">
                  <button
                    className={`region-btn ${activeRegion === item.name ? 'region-active' : ''}`}
                    onClick={changeRegion}
                  >
                    {item.name}
                  </button>
                </div>
              );
            })}
          </Col>
        )}
      </Row>
    </div>
  );

  return (
    <div
      style={{
        width: '118px',
        height: '36px',
        backgroundImage: `url(${IMAGE_LIST.screeningProvinces})`,
        backgroundSize: 'cover',
        padding: '0 10px',
        marginRight: '10px',
      }}
    >
      <Dropdown
        className="region-dropdown"
        trigger={['click']}
        placement="bottom"
        open={isOpenRegion}
        disabled={regionDisabled}
        dropdownRender={dropdownRender}
      >
        <a
          onClick={e => {
            e.preventDefault();
            if (!regionDisabled) {
              setIsOpenRegion(!isOpenRegion);
            }
            setActiveProvince(dataFilter?.globalRegion?.province?.name);
            setActiveCity(dataFilter?.globalRegion?.city?.name);
            setActiveRegion(dataFilter?.globalRegion?.region?.name);
          }}
          className={regionDisabled ? 'region-dis' : ''}
        >
          <Space>
            <span className="region-ellipsis">
              {(activeRegion !== '全部' && activeRegion) ||
                (activeCity !== '全部' && activeCity) ||
                activeProvince}
            </span>
            <CaretDownOutlined />
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default RegionFilter;
