/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-05-16 14:29:11
 * @LastEditors: chenzhen
 */

import React, { FC, useState, memo, useEffect, useCallback, useRef } from 'react';
import { Col, Dropdown, Row, Space } from 'antd';
import { CaretDownOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getInsuranceList } from 'api/common';
import { InsuranceItemType } from './types';
import { setGlobalInsurance } from 'store/action/dataFilter';
import { IMAGE_LIST } from 'utils/enumList';
import { StateTypes, UserInfosTypes, DataFilterTypes } from 'store/types';
import './index.scss';

const InsurancePanelFilter = ({ menuKey }: { menuKey: string | undefined }) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [insuranceList, setInsuranceList] = useState<InsuranceItemType[]>();
  const [insuranceCurrent, setInsuranceCurrent] = useState<string>('全部保险机构');
  const [isOpenInsurance, setIsOpenInsurance] = useState<boolean>(false);
  const userInfos: UserInfosTypes = useSelector((state: StateTypes) => state.userInfos);
  const dataFilter: DataFilterTypes = useSelector((state: StateTypes) => state.dataFilter);
  const [activeInsurance, setActiveInsurance] = useState(null);
  const [isInsuranceDisabled, setIsInsuranceDisabled] = useState(false);
  const [initializationDisabled, setInitializationDisabled] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (initializationDisabled) {
      setIsInsuranceDisabled(true);
      return;
    }
    switch (menuKey) {
      case 'homePage':
        setIsInsuranceDisabled(false);
        break;
      case 'regionSituation':
        setIsInsuranceDisabled(false);
        break;
      case 'insuranceOverview':
        setIsInsuranceDisabled(true);
        break;
      case 'statisticalAnalysis':
        setIsInsuranceDisabled(true);
        break;
      case 'runReport':
        setIsInsuranceDisabled(true);
        break;
      case 'excellentEnterprise':
        setIsInsuranceDisabled(true);
        break;
      case 'dataUpload':
        setIsInsuranceDisabled(true);
        break;
      case 'administrator':
        setIsInsuranceDisabled(true);
        break;
      case 'changeUserInfo':
        setIsInsuranceDisabled(true);
        break;
      case 'filings':
        setIsInsuranceDisabled(true);
        break;
      default:
        setIsInsuranceDisabled(false);
    }
  }, [menuKey, initializationDisabled]);

  useEffect(() => {
    let globalInsuranceData;
    if (
      menuKey &&
      [
        'insuranceOverview',
        'statisticalAnalysis',
        'runReport',
        'excellentEnterprise',
        'dataUpload',
        'administrator',
        'changeUserInfo',
      ]?.includes(menuKey)
    ) {
      globalInsuranceData = userInfos?.insurance_code
        ? { code: userInfos?.insurance_code, name: userInfos?.insurance_name }
        : { code: '全部保险机构', name: '全部保险机构' };
    } else {
      globalInsuranceData = dataFilter?.globalInsurance;
    }
    getInsuranceListAll();
    // 根据当前用户所在保司设置默认筛选权限
    if (userInfos?.insurance_code) {
      setInitializationDisabled(true);
    } else {
      setInitializationDisabled(false);
    }
    globalInsuranceData?.name && setInsuranceCurrent(globalInsuranceData?.name);
    dispatch(setGlobalInsurance(globalInsuranceData));
  }, [menuKey]);

  // 点击Dropdown外时关闭弹框
  useEffect(() => {
    function handleClickOutside(event) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpenInsurance(false);
      }
    }
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpenInsurance]);

  const getInsuranceListAll = () => {
    getInsuranceList()
      .then(res => {
        const { code, data } = res.data;
        if (code === 2000) {
          setInsuranceList(data);
        }
      })
      .catch(err => {
        console.log(err);
      });
  };
  // 修改保险机构
  const changeInsurance = useCallback(
    (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
      const target = event.target as HTMLElement;
      const insurance = insuranceList?.filter(
        (item: InsuranceItemType) => target.innerText === item.name
      );
      insurance?.[0]
        ? dispatch(setGlobalInsurance(insurance?.[0]))
        : dispatch(setGlobalInsurance({ code: '全部保险机构', name: '全部保险机构' }));
      insurance?.[0]
        ? setInsuranceCurrent(insurance?.[0]?.name)
        : setInsuranceCurrent('全部保险机构');
      setIsOpenInsurance(false);
    },
    [insuranceList]
  );
  return (
    <div
      className="insurance-panel-filter"
      style={{
        backgroundImage: `url(${IMAGE_LIST.screeningProvinces})`,
        backgroundSize: 'cover',
      }}
    >
      <Dropdown
        className="insurance-dropdown"
        trigger={['click']}
        placement="bottom"
        open={isOpenInsurance}
        disabled={isInsuranceDisabled}
        dropdownRender={menu => (
          <div className="insurance-dropdown-content" ref={dropdownRef}>
            <Row>
              <Col className="insurance-col">
                <button
                  className={`insurance-btn ${
                    activeInsurance === '全部保险机构' ? 'insurance-active' : ''
                  }`}
                  onClick={changeInsurance}
                >
                  全部保险机构
                </button>
              </Col>
            </Row>
            <Row>
              {insuranceList?.map((item, key: number) => {
                return (
                  <Col span={8} key={key} className="insurance-col">
                    <button
                      className={`insurance-btn ${
                        activeInsurance === item.name ? 'insurance-active' : ''
                      }`}
                      onClick={changeInsurance}
                    >
                      {item.name}
                    </button>
                  </Col>
                );
              })}
            </Row>
          </div>
        )}
      >
        <a
          onClick={e => {
            e.preventDefault();
            if (!isInsuranceDisabled) {
              setIsOpenInsurance(!isOpenInsurance);
            }
            setActiveInsurance(dataFilter?.globalInsurance?.name);
          }}
          className={isInsuranceDisabled ? 'insurance-dis' : ''}
        >
          <Space>
            <span className="insurance-ellipsis">{insuranceCurrent}</span>
            <CaretDownOutlined />
          </Space>
        </a>
      </Dropdown>
    </div>
  );
};

export default InsurancePanelFilter;
