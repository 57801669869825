/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-27 20:04:06
 * @LastEditors: chenzhen
 */
import * as type from '../constants/userInfos';

interface InitStateTypes {
  email: string;
  id: string;
  name: string;
  phone_number: string;
  role_id: string;
  role_name: string;
  region_code: string;
  region_name: string;
  user_name: string;
  create_time: string;
  authorityList: string[];
}

const initState = {
  email: '',
  id: '',
  name: '',
  phone_number: '',
  role_id: '',
  role_name: '',
  user_name: '',
  region_name: '',
  region_code: '',
  create_time: '',
  authorityList: [],
};

const redurce = (state: InitStateTypes = initState, action) => {
  switch (action.type) {
    case type.SET_USER_INFO:
      return Object.assign({}, state, action.infos);
    case type.SET_USER_PHONE:
      state.phone_number = action.phone;
      return Object.assign({}, state);
    case type.SET_USER_EMAIL:
      state.email = action.email;
      return Object.assign({}, state);
    case type.SET_AUTHORITY_LIST:
      state.authorityList = action.authorityList;
      return Object.assign({}, state);
    default:
      return state;
  }
};
export default redurce;
