/*
 * @Description:
 * @Author: chenz-l
 * @Date: 2024-04-27 19:31:21
 * @LastEditors: chenzhen
 */

import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import rootReducer from './reducers';

const store = (initialState = {}) => {
  return createStore(rootReducer, initialState, applyMiddleware(thunk));
};

export default store;
