/*
 * @Description: 登录滑块
 * @Author: chenz-l
 * @Date: 2024-04-30 10:56:05
 * @LastEditors: chenzhen
 */
import React, { useState, useEffect } from 'react';
import { IMAGE_LIST } from 'utils/enumList';
import './index.scss';
import { SimpleVerifyProps } from '../type';

export const SimpleVerify: React.FC<SimpleVerifyProps> = props => {
  const { simpleverifyImage } = IMAGE_LIST;
  const {
    width = 340,
    height = 36,
    borderColor = '#E4E4E4',
    bgColor = '#F2F3F5',
    borderRadius = 4,
    tips = '请按住滑块，拖动到最右边',
    barBackground = '',
    movedColor = 'linear-gradient(313deg, rgba(65, 209, 102, 1) 0%, rgba(90, 232, 118, 1) 100%)',
    successTips = '验证成功',
    successIcon = '',
  } = props;

  const [x1, setX1] = useState(0);
  const [x2, setX2] = useState(0);
  const [isMousedown, setIsMousedown] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const max = width - 40;
  const [diff, setDiff] = useState(0);
  const [isMouseEnter, setIsMouseEnter] = useState(false);
  const style = {
    width,
    height,
    border: `${borderColor} 1px solid`,
    backgroundColor: bgColor,
    borderRadius,
  };
  const slideBoxStyle = { borderRadius };

  const reset = () => {
    setIsSuccess(false);
    setDiff(0);
    setTimeout(() => {
      setIsMousedown(false);
      setIsMouseEnter(false);
    }, 0);
  };

  useEffect(() => {
    document.body.addEventListener('mousemove', mousemove);
    document.body.addEventListener('touchmove', mousemove);
    document.body.addEventListener('mouseup', mouseup);
    document.body.addEventListener('touchend', mouseup);
  }, [isMousedown, isSuccess, x1, x2, diff]);

  useEffect(() => {
    return () => {
      document.body.removeEventListener('mousemove', mousemove);
      document.body.removeEventListener('touchmove', mousemove);
      document.body.removeEventListener('mouseup', mouseup);
      document.body.removeEventListener('touchend', mouseup);
    };
  });

  const mouseenter = () => {
    if (isSuccess) {
      return;
    }
    setIsMouseEnter(true);
  };

  const mouseleave = () => {
    if (isSuccess || isMousedown) {
      return;
    }
    setIsMouseEnter(false);
  };

  const mousedown = e => {
    if (isSuccess || isMousedown) {
      return;
    }
    setX1(e.nativeEvent.x || e.touches[0].clientX);
    setIsMousedown(true);
  };

  const mousemove = e => {
    if (!isMousedown || isSuccess) {
      return;
    }
    e.preventDefault();
    e.stopPropagation();
    setX2(e.x || e.touches[0].clientX);

    let diff = x2 - x1;

    if (diff < 0) {
      diff = 0;
    }
    if (diff >= max) {
      diff = max;
      setIsSuccess(true);
      props.success && props.success();
    }
    setDiff(diff);
  };

  const mouseup = () => {
    if (isSuccess) {
      return;
    }
    setIsMousedown(false);
    setIsMouseEnter(false);
    setDiff(0);
  };

  const slideStyle = {
    borderRadius: borderRadius,
    background: movedColor,
    left: 39 - width,
    opacity: isMouseEnter ? 1 : 0,
    transitionDuration: !isMouseEnter || !isMousedown ? '.3s' : '0s',
    transform: `translateX(${diff}px)`,
  };
  const barStyle = {
    transitionDuration: !isMouseEnter || !isMousedown ? '.3s' : '0s',
    transform: `translateX(${diff}px)`,
  };
  const textStyle = {
    opacity: isSuccess ? 1 : 0,
    transitionDuration: !isMouseEnter || !isMousedown ? '.3s' : '0s',
  };

  return (
    <div style={style} className="simple-verify">
      <div className="verify-tips">{!isSuccess ? tips : ''}</div>
      <div style={slideBoxStyle} className="verify-box">
        <div style={slideStyle} className="veriry-slide" />
      </div>
      <div
        className="verify-bar"
        onMouseEnter={mouseenter}
        onTouchStart={mouseenter}
        onMouseLeave={mouseleave}
        onTouchEnd={mouseleave}
        onMouseDown={mousedown}
        onTouchMove={mousedown}
      >
        <div style={barStyle} className="icon">
          <img src={simpleverifyImage} alt="" />
        </div>
      </div>
      <div style={textStyle} className="verify-success-tips">
        {successTips}
      </div>
    </div>
  );
};
