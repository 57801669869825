/*
 * @Description:
 * @Author: chenzhen
 * @Date: 2024-05-21 11:02:43
 * @LastEditors: chenzhen
 */
import React, { useEffect, useState, useMemo, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Popover, Space, Avatar } from 'antd';
import { BrowserRouter as Router, Route, Routes, useNavigate } from 'react-router-dom';
import { AUTHORITY_LIST } from 'utils/enumList';
import { Logout } from 'api/loginApi';
import { IMAGE_LIST } from 'utils/enumList';
import RegionFilter from './GlobalFilter/RegionFilter';
import InsurancePanelFilter from './GlobalFilter/InsurancePanelFilter';
import YearsFilter from './GlobalFilter/YearsFilter';
import { StateTypes, UserInfosTypes, DataFilterTypes } from 'store/types';
import { setGlobalRegion } from 'store/action/dataFilter';
const { Header, Content } = Layout;

import './index.scss';

interface MainLayoutProps {
  children;
  routePrefix: string;
  menuList;
  className: string;
  breadcrumb: boolean;
}

export const MainLayout = (props: MainLayoutProps) => {
  const { children, menuList, routePrefix, className } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [isActive, setIsActive] = useState(false);
  const [menuKey, setMenuKey] = useState<string>();
  const userInfos: UserInfosTypes = useSelector((state: StateTypes) => state.userInfos);
  const dataFilter: DataFilterTypes = useSelector((state: StateTypes) => state.dataFilter);
  const dispatch = useDispatch();

  const environment = useMemo(() => window.sessionStorage.getItem('environment'), []);

  // 退出登录
  const handleLogout = () => {
    navigate('/login');
    Logout().then(res => {
      location.href = `/login`;
    });
  };

  // 点击菜单栏
  const handleMenuItem = (menuItem, index: number) => {
    setActiveIndex(index);
    setIsActive(!isActive);
    setMenuKey(menuItem.key);
    navigate(menuItem.key);
  };

  const getActiveClassName = (index: number) => {
    return activeIndex === index ? 'active' : '';
  };

  useEffect(() => {
    if (location.pathname === '/administrator') {
      setMenuKey('administrator');
    } else if (location.pathname === '/homePage') {
      setMenuKey('homePage');
    } else if (
      location.pathname === '/changeUserInfo/personalCenter' ||
      location.pathname === '/changeUserInfo/changePassword'
    ) {
      setMenuKey('changeUserInfo');
    }
  }, [location.pathname]);

  // 个人信息修改
  const changeIngoFn = useCallback(() => {
    setActiveIndex(-1);
    userInfos?.authorityList.includes(AUTHORITY_LIST.USER_XGMM) &&
      navigate('/changeUserInfo/changePassword');
    userInfos?.authorityList.includes(AUTHORITY_LIST.USER_GRZX) &&
      navigate('/changeUserInfo/personalCenter');
  }, [userInfos]);

  const popoverContent = useMemo(() => {
    return (
      <div className="popover-box">
        <div>{userInfos?.user_name || '--'}</div>
        <div>{userInfos?.region_name || '--'}</div>
        <div>{userInfos?.role_name || '--'}</div>
        {userInfos?.authorityList.includes(AUTHORITY_LIST.USER_GRXXXG) && (
          <div className="change-info" onClick={changeIngoFn}>
            个人信息修改
          </div>
        )}
        <div className="logout" onClick={handleLogout}>
          退出登录
        </div>
      </div>
    );
  }, [
    userInfos?.name,
    userInfos?.role_id,
    userInfos?.role_name,
    userInfos?.region_name,
    userInfos?.authorityList,
    handleLogout,
  ]);

  const getMenuNameList = menuList.map((item, index: number) => {
    let regionCodeFilter = '';
    let regionLevel = '';
    if (dataFilter?.globalRegion.province.code) {
      regionCodeFilter = dataFilter?.globalRegion.province.name;
      regionLevel = 'PROVINCE';
    }
    if (dataFilter?.globalRegion.city.code !== '全部') {
      regionCodeFilter = dataFilter?.globalRegion.city.name;
      regionLevel = 'CITY';
    }
    if (dataFilter?.globalRegion.region.code !== '全部') {
      regionCodeFilter = dataFilter?.globalRegion.region.name;
      regionLevel = 'REGION';
    }
    const homePageName = regionCodeFilter + '情况';
    const regionSituationName = regionLevel === 'PROVINCE' ? '各地市情况' : '各区县情况';
    return (
      <li
        className={getActiveClassName(index)}
        key={index}
        onClick={() => handleMenuItem(item, index)}
        style={{
          backgroundImage:
            activeIndex === index
              ? `url(${IMAGE_LIST.navMenusActiveBg})`
              : `url(${IMAGE_LIST.navMenusBg})`,
          backgroundSize: 'cover',
        }}
      >
        <span>
          {item.key === 'homePage'
            ? homePageName
            : item.key === 'regionSituation'
            ? regionSituationName
            : item.text}
        </span>
      </li>
    );
  });

  useEffect(() => {
    location.pathname === '/changeUserInfo/personalCenter' && setActiveIndex(-1);
    userInfos.user_name && setUserName(userInfos.user_name);
  }, [userInfos]);

  return (
    <Layout
      className="main-layout"
      style={{
        backgroundImage: `url(${IMAGE_LIST.dapingbg})`,
        backgroundSize: 'cover',
      }}
    >
      {userName ? (
        <>
          <div className="header">
            <div
              className="header-title"
              style={{
                backgroundImage: `url(${IMAGE_LIST.headerbg})`,
                backgroundRepeat: 'round',
              }}
            >
              <h1>
                安责险综合信息管理平台{environment && <span className="env">{environment}</span>}
              </h1>
              <div className="user-info">
                <Popover
                  content={popoverContent}
                  trigger="click"
                  overlayStyle={{
                    width: '172px',
                    backgroundImage:
                      'linear-gradient(89deg, rgba(14,37,76,0.6) 1%, rgba(9,21,42,0.6) 100%)',
                    boxShadow: '0px 8px 20px 0px rgba(0,0,0,0.4)',
                    border: '1px solid rgba(56,225,255,0.3)',
                    backdropFilter: 'blur(10px)',
                  }}
                  overlayClassName="userinfo-popover"
                >
                  <Space>
                    <Avatar
                      size={24}
                      style={{
                        fontSize: '14px',
                        color: '#FFFFFF',
                        backgroundColor: '#2CD6F7',
                      }}
                    >
                      {userName?.substring(0, 1).toUpperCase()}
                    </Avatar>
                    <span>{userName || '--'}</span>
                  </Space>
                </Popover>
              </div>
            </div>
            <div className="header-nav">
              <ul>{getMenuNameList}</ul>
              <div className="global-filter">
                <RegionFilter menuKey={menuKey}></RegionFilter>
                <InsurancePanelFilter menuKey={menuKey}></InsurancePanelFilter>
                <YearsFilter menuKey={menuKey}></YearsFilter>
              </div>
            </div>
          </div>
          <Content>{children}</Content>
        </>
      ) : null}
    </Layout>
  );
};
